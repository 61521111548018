<template>
  <div class="col-lg-4 col-sm-6 col-12 contactItem text-lg-left text-sm-center text-center">
    <h4><strong>{{ this.contact.name }}</strong></h4>
    <p>{{ this.contact.role | translation }}</p>
    <p>{{ this.contact.phoneNumber }}</p>
    <a :href="'mailto:' + this.contact.email">{{ this.contact.email }}</a>
  </div>
</template>
ś
<script>
export default {
  name: "contactRow",
  props: ['contact']
}
</script>

<style scoped>
.contactItem {
  padding-bottom: 4em;
}

h4 {
  font-family: "Transcript Pro";
  padding-bottom: 10px;
}

p {
  font-family: "Transcript Mono";
  margin-bottom: .4rem;
  color: #4c4d4f;
}

a:hover {
  margin-bottom: .4rem;
  color: black;
  text-decoration: none;
}

a {
  font-family: "Transcript Mono";
  margin-bottom: .4rem;;
  color: black;
  text-decoration: underline;
}
</style>
